<template>
  <div class="er404-wrap">
    <h1 class="jumbo">O_O!</h1>
    <h2 class="tip">您访问的页面不存在</h2>
    <button class="erbtn cflex" @click="sureFun()">回到首页</button>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    sureFun() {
      this.$router.replace({ name: 'index' });
    }
  }
}
</script>

<style lang='less' scoped>
.er404-wrap{
  width: 100%;
  height: 100%;
  overflow: hidden;
  .jumbo{
    font-size: 46px;
    text-align: center;
    padding-top: 150px;
    margin-bottom: 20px;
  }
  .tip{
    text-align: center;
    font-size: 14px;
    color: #999;
    margin-bottom: 60px;
  }
  .erbtn{
    display: block;
    width: 98px;
    height: 40px;
    font-size: 14px;
    color: #fff;
    background: #409eff;
    margin: 0 auto;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
}
</style>